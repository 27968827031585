import React from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import eye from "../../../images/pageIcon/eye.svg";
import darkEye from "../../../images/pageIcon/darkEye.svg";
import download from "../../../images/pageIcon/download.svg";
import downloadFilled from "../../../images/pageIcon/downloadfilled.svg";

const GiverHistory = ({ giverVideos }) => {
  const hData = [
    {
      id: 1,
      th: "#",
    },
    {
      id: 2,
      th: "Package Type",
    },
    {
      id: 3,
      th: "Date",
    },
    {
      id: 4,
      th: "Status",
    },
  ];

  return (
    <>
      <Table responsive className="header-border ">
        <thead>
          <tr>
            {hData.map((item) => (
              <th className="greyColor fs-14 fw-600" key={item.id}>
                {item.th}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {giverVideos?.map((item) => (
            <tr key={item.id}>
              <td className="greyColor fs-16 fw-500">
                <Link href="#">{item.id}</Link>
              </td>
              {console.log(item)}
              <td className="greyColor fs-16 fw-500">
                {item?.PackageId === 1
                  ? "littleknowitall"
                  : item?.PackageId === 2
                  ? "varsitybaby"
                  : item?.PackageId === 3
                  ? "upperclassmen"
                  : "--"}
              </td>
              <td className="greyColor fs-16 fw-500">
                {new Date(item.createdAt).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </td>
              <td className="greyColor fs-16 fw-500">
                {item?.IsVideoCompleted ? "completed" : "inDraft"}
              </td>
              <td className="greyColor fs-16 fw-500">
                <div className="d-flex">
                  <div className="mr-3 cursorPointer">
                    <a target="_blank" href={item?.UserVideoLink}>
                      <img
                        className="w-100"
                        src={item?.IsVideoCompleted ? downloadFilled : download}
                      />
                    </a>
                  </div>
                  <div className="cursorPointer">
                    <Link to={`/giver/videomedia/${item?.id}`}>
                      <img className="w-100" src={darkEye} />
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {giverVideos?.length === 0 && (
        <div className="d-flex justify-content-center">
          <div>
            <h4>No record found</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default GiverHistory;
