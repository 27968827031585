import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
//import rootReducers from './reducers/Index';
import todoReducers from "./reducers/Reducers";
import giverReducer from "./reducers/giversReducer";
import analyticsReducer from "./reducers/analyticsReducer";
import packageReducer from "./reducers/packageReducer";
import blogReducer from "./reducers/blogReducer";
import notificationReducer from "./reducers/notificationReducer";
import learningVideosReducer from "./reducers/learningVideosReducer";
import { reducer as reduxFormReducer } from "redux-form";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  giverReducer,
  analyticsReducer,
  packageReducer,
  blogReducer,
  notificationReducer,
  learningVideosReducer,
  form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
