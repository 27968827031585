import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { BiMessageSquareEdit } from "react-icons/bi";
import { useState } from "react";
import { Button, Modal, Box, Typography } from "@material-ui/core";
import { Form, FormGroup, Label, Input, Row, Col, Card } from "reactstrap";
import { FaEye } from "react-icons/fa";
import { MdArrowBackIosNew } from "react-icons/md";
import { CgClose } from "react-icons/cg";

import { logout } from "../../../store/actions/AuthActions";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";

import { changePassword } from "../../../store/actions/AuthActions";
import { toast, ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: "24px 32px",
};

function LogoutPage(props) {
  const dispatch = useDispatch();

  function onLogout() {
    dispatch(logout(props.history));
    window.location.reload();
  }

  const [oldPassView, setOldPassView] = useState("password");
  const [newPassView, setNewPassview] = useState("password");
  const [warning, setWarning] = useState(false);

  const [openPass, setOpenPass] = React.useState(false);
  const handleOpenPass = () => setOpenPass(true);
  const handleClosePass = () => setOpenPass(false);

  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function handleOldPassView() {
    if (oldPassView === "password") {
      setOldPassView("text");
    } else {
      setOldPassView("password");
    }
  }

  function handleNewPassView() {
    if (newPassView === "password") {
      setNewPassview("text");
    } else {
      setNewPassview("password");
    }
  }

  const handlePasswordDetails = (e) => {
    const { name, value } = e.target;
    if (
      e.target.name !== "oldPassword" &&
      passwordDetails.newPassword !== e.target.value
    ) {
      setWarning(true);
    } else {
      setWarning(false);
    }
    setPasswordDetails({
      ...passwordDetails,
      [name]: value,
    });
  };

  const onSubmitPass = (e) => {
    e.preventDefault();
    console.log("password details:", passwordDetails);
    dispatch(changePassword(passwordDetails));
  };

  return (
    <>
      <Link to="#" className="dropdown-item ai-icon" onClick={handleOpenPass}>
        {/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          // width="800.000000pt" height="800.000000pt"
          width={22}
          height={22} 
          viewBox="0 0 800.000000 800.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M3809 7616 c-370 -54 -718 -209 -930 -416 -83 -81 -143 -167 -209
          -300 l-43 -85 -86 -40 c-47 -22 -140 -71 -206 -109 -877 -500 -1491 -1376
          -1654 -2361 -66 -395 -62 -813 10 -1195 87 -458 274 -905 537 -1285 166 -241
          439 -535 657 -709 478 -380 1029 -623 1615 -711 47 -7 87 -11 89 -9 9 8 -179
          1571 -261 2163 l-91 665 32 130 c27 114 31 148 31 262 l0 132 -97 78 c-122 98
          -291 260 -351 338 -67 87 -123 240 -187 510 -25 107 -26 109 -84 166 -178 179
          -230 530 -98 658 l34 33 12 -103 c24 -203 72 -523 82 -550 12 -29 17 -32 43
          -22 24 9 21 158 -6 332 -19 125 -20 151 -9 205 22 112 52 189 156 407 111 231
          150 332 185 479 20 81 30 103 80 170 117 158 203 200 432 209 235 9 405 -33
          844 -209 263 -105 353 -131 454 -132 87 -1 124 13 156 60 l22 33 37 -108 c20
          -59 77 -210 127 -337 112 -285 192 -512 219 -621 21 -80 21 -86 5 -193 -9 -60
          -16 -150 -16 -199 0 -89 5 -102 40 -102 12 0 28 50 35 110 3 30 19 120 35 200
          16 80 34 189 41 243 8 60 17 96 23 94 6 -1 25 -30 41 -62 28 -56 30 -67 29
          -165 -2 -168 -61 -318 -173 -435 -44 -46 -49 -57 -71 -150 -57 -248 -111 -406
          -169 -494 -48 -74 -211 -236 -354 -351 l-117 -94 0 -131 c0 -99 6 -161 26
          -260 l25 -130 -75 -580 c-41 -319 -82 -641 -91 -715 -8 -74 -20 -171 -25 -215
          -5 -44 -21 -177 -35 -295 -13 -118 -43 -368 -65 -555 -58 -498 -57 -478 -34
          -472 10 3 44 9 74 12 172 21 470 98 680 177 1145 427 1973 1454 2149 2664 91
          623 9 1251 -239 1831 -303 706 -880 1321 -1561 1664 -44 22 -82 47 -85 57 -3
          9 -19 53 -36 97 -86 223 -222 367 -402 425 -22 7 -66 32 -97 55 -132 97 -335
          183 -534 225 -130 28 -425 36 -566 16z"/>
          <path d="M3214 5591 c-83 -38 -127 -136 -104 -232 13 -55 55 -111 101 -135 33
          -17 102 -18 132 -3 103 53 141 180 87 289 -22 44 -45 65 -93 85 -47 20 -73 19
          -123 -4z"/>
          <path d="M4665 5599 c-139 -40 -168 -264 -47 -357 30 -23 45 -27 97 -27 51 0
          67 4 97 27 63 48 94 154 68 233 -17 52 -75 113 -118 125 -43 11 -56 11 -97 -1z"/>
          </g>
        </svg> */}
        <BiMessageSquareEdit color="black" fontSize={16} />
        <span className="ml-2">Change Password </span>
      </Link>

      <Link className="dropdown-item ai-icon" onClick={onLogout}>
        <svg
          id="icon-logout"
          xmlns="http://www.w3.org/2000/svg"
          className="text-danger"
          width={18}
          height={18}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1={21} y1={12} x2={9} y2={12} />
        </svg>
        <span className="ml-2">Logout </span>
      </Link>

      <Modal
        open={openPass}
        onClose={handleClosePass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="box-style">
          <div className="d-flex justify-content-between">
            <span
              className="d-flex"
              style={{ cursor: "pointer", width: "10%" }}
              onClick={handleClosePass}
            >
              {/* <p> <MdArrowBackIosNew fontSize={22}/> </p> */}
            </span>
            <ToastContainer />

            <p
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={handleClosePass}
            >
              <CgClose />
            </p>
          </div>
          {/* <p style={{textAlign:"end", fontSize:"24px", cursor:"pointer"}} onClick={handleClose}>Change Pasword</p> */}
          <Form
            onSubmit={(e) => {
              onSubmitPass(e);
            }}
          >
            <div>
              <div className="mb-4">
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <Label className="font-size-25 fw-500 mb-2">
                      Old Password
                    </Label>
                  </div>

                  <div
                    className="d-flex justify-content-end mx-3"
                    style={ warning ?  {position: "absolute", left: "82%", top:"24.5%"} : {position: "absolute", left: "82%", top:"26.5%"} }
                  >
                    <FaEye
                      color={oldPassView === "text" ? "red" : "blue"}
                      className="FaEye"
                      onClick={handleOldPassView}
                    />
                  </div>

                  <Input
                    // minLength="8"
                    className="bg-grayModalInput"
                    name="oldPassword"
                    value={passwordDetails.oldPassword}
                    type={oldPassView}
                    placeholder="old password"
                    id="old-password"
                    onChange={(e) => handlePasswordDetails(e)}
                    required
                  />
                </FormGroup>
              </div>

              <div className="mb-4">
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <Label className="font-size-25 fw-500 mb-2">
                      New Password
                    </Label>
                  </div>

                  <div
                    className="d-flex justify-content-end mx-3"
                    style={ warning ?  {position: "absolute", left: "82%", top:"45%"} : {position: "absolute", left: "82%", top:"48.5%"} }
                  >
                    <FaEye
                      color={newPassView === "text" ? "red" : "blue"}
                      className="FaEye"
                      onClick={handleNewPassView}
                    />
                  </div>

                  <Input
                    minLength="8"
                    className="bg-grayModalInput"
                    name="newPassword"
                    value={passwordDetails.newPassword}
                    type={newPassView}
                    placeholder="new password"
                    id="new-password"
                    onChange={(e) => handlePasswordDetails(e)}
                    required
                  />
                </FormGroup>
              </div>
              <div className="mb-4">
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Label className="font-size-25 fw-500 mb-2">
                      Confirm Password
                    </Label>
                  </div>
                  <Input
                    type={newPassView}
                    minLength="8"
                    // value={password?.newPassword2}
                    // onChange={passwordChange}
                    className="bg-grayModalInput"
                    value={passwordDetails.confirmPassword}
                    name="confirmPassword"
                    placeholder="confirm password"
                    id="confirm-password"
                    onChange={(e) => handlePasswordDetails(e)}
                    required
                  />
                </FormGroup>
              </div>

              <p style={{ fontSize: "14px", color: "red" }}>
                {warning && "* New Password do not match with confirm password"}{" "}
              </p>
              <Button
                type="submit"
                disabled={warning === true ? true : false}
                className="w-100 fw-600 editProfileUpdateButton mt-2 text-light"
              >
                Update
              </Button>
            </div>
          </Form>
        </Box>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));
