import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import cross from "../../../images/pageIcon/cross.svg";
import { number } from "yup";
import ActionsModal from "./actionsModal";

const PriceEditModal = ({
  modalCentered,
  setModalCentered,
  packageForModal,
}) => {
  const dispatch = useDispatch();

  const [anotherModal, setAnotherModal] = useState(false);

  const [price, setPrice] = useState({
    basic: null,
    dvd: null,
  });

  useEffect(() => {
    setPrice({
      ...price,
      basic: packageForModal?.PackagePrice,
      dvd: packageForModal?.DVDPrice,
    });
  }, [packageForModal]);

  const onUpdate = () => {
    let data = {
      id: packageForModal?.id,
      PackageNumber: packageForModal?.PackageNumber,
      PackageName: packageForModal?.PackageName,
      PackagePrice: parseFloat(price.basic),
      DVDPrice: parseFloat(price.dvd),
    };
    dispatch(actions.updatePackage(data));
    setModalCentered(false);
    console.log(data);
  };

  const onBlurPrice = () => {
    console.log("this is called");
    setPrice({
      ...price,
      basic: Number(price.basic).toFixed(2),
      dvd: Number(price.dvd).toFixed(2),
    });
  };

  const AnotherModal = () => {
    setModalCentered(false);
    setAnotherModal(true);
  };

  return (
    <>
      {/* <!-- Button trigger modal --> */}

      {/* <!-- Modal --> */}
      <Modal className="fade" centered show={modalCentered}>
        <div
          onClick={() => setModalCentered(false)}
          className="cursor-pointer d-flex justify-content-end px-4 pt-3"
        >
          <img src={cross} />
        </div>
        <Modal.Body>
          <form>
            <div className="my-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="m-0 dBlackColor fs-24 fw-600">Basic Price</p>
                <div style={{ width: "100px" }}>
                  <input
                    style={{
                      background: "#F5F9F1",
                      border: "none",
                      height: "35px",
                    }}
                    onChange={(e) =>
                      setPrice({ ...price, basic: e.target.value })
                    }
                    onBlur={onBlurPrice}
                    name="packagePrice"
                    value={price.basic}
                    plac
                    min="0"
                    step="0.01"
                    type="number"
                    className="w-100 px-2"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="m-0 dBlackColor fs-24 fw-600">
                  DVD Request - S&H
                </p>
                <div style={{ width: "100px" }}>
                  <input
                    style={{
                      background: "#F5F9F1",
                      border: "none",
                      height: "35px",
                    }}
                    onChange={(e) =>
                      setPrice({ ...price, dvd: e.target.value })
                    }
                    onBlur={onBlurPrice}
                    name="packagePrice"
                    value={price.dvd}
                    plac
                    min="0"
                    step="0.01"
                    type="number"
                    className="w-100 px-2"
                  />
                </div>
              </div>

              <div className="mt-3">
                <Button onClick={AnotherModal} className="w-100 bgDGreen py-3">
                  Update
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ActionsModal
        confirmCall={onUpdate}
        modalCentered={anotherModal}
        setModalCentered={setAnotherModal}
        action="priceChange"
      />
    </>
  );
};

export default PriceEditModal;
