import axios from "axios";
import Domain from "../../../lib/config";

export const uploadFile = (event) => {
  // For Local file upload...........................
  const data = new FormData();
  data.append("UserFile", event.target.files[0]);

  let config = {
    method: "POST",
    url: "/uservideo/uploadLearningData",
    data: data,
    baseURL: Domain,
  };
  let localUpload = axios(config);

  // For s3 file upload...........................
  let configS3 = {
    method: "POST",
    url: "/uservideos/uploadfiletosebucket",
    data: data,
    baseURL: Domain,
  };
  let s3Upload = axios(configS3);

  return Promise.all([localUpload, s3Upload]);
};
