import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import Domain from "../lib/config";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  // return axios.post(`https://api.fromubaby-videos.com/admin/login`, postData);
  return axios.post(`${Domain}/admin/login`, postData);
}

export function resetMail(email) {
  const postEmail = {
    email
  }
  console.log("postEmail:", postEmail)
  return axios.post(`${Domain}/admin/resetPasswordMail`, postEmail);
}

export function resetPass(email, emToken, password, password2) {
  const postData = {
    email,
    emToken,
    password,
    password2
  }
  console.log("postData:", postData)
  return axios.post(`${Domain}/admin/resetPassword`, postData);
}

export function changePass(oldPassword, password, password2) {
  const postData = {
    oldPassword, password, password2
  }
  console.log("postData:", postData)

  // token in the headers
  const userDetails = JSON.parse(localStorage.getItem("userDetails"))
  console.log("local:", userDetails)
  const token = userDetails.idToken;
  console.log("token:", token)

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axios.post(`${Domain}/admin/changePassword`, postData);
}

export function formatError(errorResponse) {
  switch (errorResponse?.error?.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //   new Date().getTime() + tokenDetails.expiresIn * 1000
  // );

  // console.log("token details:", tokenDetails)
  // axios.defaults.headers.common['Authorization'] = `Bearer ${tokenDetails}`;

  console.log("i am called")
  localStorage.setItem(
    "userDetails",
    JSON.stringify({
      idToken: tokenDetails,
      registered: true,
    })
  );
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();

  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  dispatch(loginConfirmedAction(tokenDetails));

  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
