import * as Actions from "../actions";

const initialState = {
  loading: false,
  packages: null,
};

const packageReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PACKAGES_DETAIL: {
      return {
        ...state,
        packages: action.payload?.package,
        loading: false,
      };
    }

    case Actions.GET_PACKAGES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default packageReducer;
