import React, { useEffect, useState } from "react";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import Packages from "./packages";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";

const ManagePackages = () => {
  const dispatch = useDispatch();

  const data = useSelector(({ packageReducer }) => packageReducer);

  const packages = data?.packages;

  useEffect(() => {
    dispatch(actions.getPackagesDetail());
  }, []);

  return (
    <>
      <Col lg={12}>
        <Card>
          <Packages packages={packages} />
        </Card>
      </Col>
    </>
  );
};

export default ManagePackages;
