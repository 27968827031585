import React from "react";
import { Col, Card, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import totalRevenueImg from "../../../images/pageIcon/totalRevenue.svg";
import totalGiversImg from "../../../images/pageIcon/totalGivers.svg";
import totalVideosImg from "../../../images/pageIcon/totalVideos.svg";

const TotalCount = ({ totalRevenue, totalVideos, totalGivers }) => {
  const data = [
    {
      id: 1,
      img: totalRevenueImg,
      heading: "Total Revenue",
      total:
        totalRevenue && Math.round((totalRevenue + Number.EPSILON) * 100) / 100,
    },
    {
      id: 2,
      img: totalGiversImg,
      heading: "Total Givers",
      total: totalGivers && totalGivers,
    },
    {
      id: 3,
      img: totalVideosImg,
      heading: "Total Videos",
      total: totalVideos && totalVideos,
    },
  ];

  return (
    <>
      <Col xl={4}>
        <div>
          {data.map((item) => (
            <div key={item.id} className="widget-stat card">
              <div className="card-body p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <img className="w-100" src={item.img} />
                  </div>
                  <div className="text-center">
                    <p className="greyColor fw-500 fs-18">{item.heading}</p>
                    <p className="dGreenColor fw-600 fs-24">{item.total}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

export default TotalCount;
