import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Designed &amp; Developed by{" "}
          <a
            href="https://www.infinitybits.pk/"
            target="_blank"
            rel="noreferrer"
          >
            InfintiyBits
          </a>{" "}
          2021
        </p>
      </div>
    </div>
  );
};

export default Footer;
