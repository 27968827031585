import React, { useEffect, useState } from "react";
import { Col, Card, Button, Dropdown } from "react-bootstrap";
import VideoAddModal from "../../components/modals/videoAddModal";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import PreLoderVideos from "../../pages/Loader/preloaderVideos";
import ActionsModal from "../../components/modals/actionsModal";
import videoThumbnail from "../../../images/videothumbnail.jpg";

const Videos = () => {
  const dispatch = useDispatch();
  const [selectedCat, setSelectedCat] = useState("Select Cat");
  const [modalCentered, setModalCentered] = useState(false);
  const [videoModalCentered, setVideoModalCentered] = useState(false);
  const [videoId, setVideoId] = useState(false);

  const data = useSelector(
    ({ learningVideosReducer }) => learningVideosReducer
  );

  const categories = data?.getAllCategories?.category;
  const videoss = data?.getAllVideos;

  const loading = data?.loading;

  const filteredVideos = videoss?.filter((item) => {
    if (item?.LearningCategoryId === selectedCat?.id) {
      return item;
    }
  });

  useEffect(() => {
    if (categories) {
      setSelectedCat(categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    dispatch(actions.LearningVideosLoading(true));
    dispatch(actions.getVideosCategories({}));
    dispatch(actions.getAllVideos({}));
  }, []);

  const deleteModal = (id) => {
    setModalCentered(!modalCentered);
    setVideoId(id);
  };

  const deletVideo = () => {
    let data = {
      id: videoId,
    };
    dispatch(actions.deleteVideos(data, setModalCentered));
    dispatch(actions.actionLearningVideoLoading(true));
    // setModalCentered(!modalCentered);
  };

  return (
    <>
      <Col lg={12}>
        <Card>
          <div className="p-4">
            <div className="d-flex justify-content-end align-items-center">
              <div className="mr-3">
                <div>
                  <Button
                    onClick={() => setVideoModalCentered(!videoModalCentered)}
                    variant="outline-light"
                  >
                    Add Video
                  </Button>
                </div>
              </div>
              <div>
                <Dropdown className="dropdown">
                  <Dropdown.Toggle
                    style={{ width: "190px" }}
                    as="button"
                    variant=""
                    className="btn d-flex align-items-center justify-content-between rounded border border-light dropdown-toggle"
                  >
                    <div className="text-truncate">
                      {selectedCat?.LearningVideoCategoryName}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    {categories?.map((item) => (
                      <Dropdown.Item
                        onClick={() => setSelectedCat(item)}
                        key={item.id}
                      >
                        {item.LearningVideoCategoryName}
                      </Dropdown.Item>
                      //
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {!loading ? (
              <div style={{ gap: "20px" }} className="d-flex mt-5 flex-wrap">
                {filteredVideos?.map((item) => (
                  <div key={item?.id} className="">
                    <div className="py-2  px-4 videoContentDiv">
                      <h4 style={{ width: "250px" }} className="text-truncate">
                        {item.LearningVideoName}
                      </h4>
                      <img src={videoThumbnail} width="283" height="180" />
                      <div className="d-flex justify-content-end">
                        <div className="bg-danger px-2 mt-1 br-8">
                          <p
                            onClick={() => deleteModal(item?.id)}
                            className="m-0 text-white cursorPointer"
                          >
                            Delete
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <PreLoderVideos />
            )}
          </div>
        </Card>
        <ActionsModal
          confirmCall={deletVideo}
          action="delete"
          modalCentered={modalCentered}
          setModalCentered={setModalCentered}
        />
        <VideoAddModal
          categories={categories}
          modalCentered={videoModalCentered}
          setModalCentered={setVideoModalCentered}
        />
      </Col>
    </>
  );
};

export default Videos;
