import * as domain from "./../../lib/config";
import history from "../../@history";
import axios from "axios";
export const GET_BLOGS = "[ADMIN] GET_BLOGS";
export const BLOGS_LOADING = "[ADMIN] BLOGS_LOADING";
export const GET_BLOG_BY_ID = "[ADMIN] GET_BLOG_BY_ID";

export function addBlog(data) {
  const request = axios.post(`${domain.default}/Blog/AddBlog`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        history.push("/blogs");
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getBlogs(data) {
  const request = axios.post(`${domain.default}/blog/getallBlog`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_BLOGS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getBlogById(data) {
  const request = axios.post(`${domain.default}/Blog/getBlogById`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_BLOG_BY_ID,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function deleteBlog(data) {
  console.log(data);
  const request = axios.delete(`${domain.default}/Blog/deleteBlog`, {
    data,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch(getBlogs());
      })
      .catch((error) => {
        console.log(error);
      });
}

export function updateBlog(data) {
  const request = axios.put(`${domain.default}/Blog/updateBlog`, data);
  return (dispatch) =>
    request
      .then((response) => {
        history.push("/blogs");
      })
      .catch((error) => {
        console.log(error);
      });
}

export function blogLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: BLOGS_LOADING,
      payload: val,
    });
  };
}
