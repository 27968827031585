import {
  formatError,
  login,
  resetMail,
  resetPass,
  changePass,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        console.log("response:", response);
        saveTokenInLocalStorage(response.data.result.access_token);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(loginConfirmedAction(response.data));
        toast.success("Login Successfull!")
        
        setInterval(()=>{
          history.push("/analytics");
          window.location.reload();
        }, 1000)
        //history.pushState('/index');
      })
      .catch((error) => {
        toast.error("Login Failed! Check Your Input")
        const errorMessage = formatError(error?.response?.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function resetPasswordMail(email, history){
  // toast.info("Processing...")
  console.log("hi:", email)
  // email = 0;
  return (dispatch) => {
    resetMail(email)
    .then((response)=>{
      console.log("response:", response)
      toast.info(response.data.msg)
      setTimeout(()=>{
        history.push("/page-forgot-password-reset")
      }, 1000)
    })
    .catch((error) => {
      // const errorMessage = formatError(error?.response?.data);
      // dispatch(loginFailedAction(errorMessage));
      console.log("error:", error)
      toast.error(error.response.data.msg)
      // history.push("/page-forgot-password-error")
    });
  }
}

export function resetPassword(data, history){
  console.log("data:", data)
  const {email, emToken, password, password2} = data
  return (dispatch) => {
    resetPass(email, emToken, password, password2)
    .then((response)=>{
      console.log("response:", response)
      toast.success(response.data.msg)
      setTimeout(()=>{
        history.push("/login")
      }, 1000)
      
    })
    .catch((error) => {
      // const errorMessage = formatError(error?.response?.data);
      // dispatch(loginFailedAction(errorMessage));
      toast.error(error.response.data.msg)
      console.log("error:", error)
    });
  }
}

export function changePassword(data){
 
  console.log("data:", data)
  const {oldPassword, newPassword, confirmPassword} = data
  return (dispatch) => {
    changePass(oldPassword, newPassword, confirmPassword)
    .then((response)=>{
      console.log("response:", response)
      toast.success(response.data.msg)
      setTimeout(()=>{
        window.location.reload();
      }, 1000)
    })
    .catch((error) => {
      toast.error(error.response.data.error)
      console.log("error:", error)
    });
  }
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
