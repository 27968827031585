import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import giverImg from "../../../images/pageIcon/giverImg.png";
import mediaGalleryAudio from "../../../images/pageIcon/mediaGalleryAudio.svg";
import audioDownloadButton from "../../../images/pageIcon/audioDownloadButton.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import Loader from "../../pages/Loader/Loader";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";

const GiverVideoMedia = ({}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector(({ giverReducer }) => giverReducer);

  const videoMediaPics = data?.videoMedia?.picsLinks;
  const videoMediaAudio = data?.videoMedia?.AudioLink;

  const loading = data?.loading;

  useEffect(() => {
    let data = {
      userVideoId: id,
    };

    dispatch(actions.giversLoading(data));
    dispatch(actions.getVideoMedia(data));
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center w-100 align-items-center"
        style={{ height: "80vh" }}
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <div>
            <p className="m-0 fs-20 fw-500 mb-3">Images</p>
            <div
              style={{ gap: "50px" }}
              className="d-flex flex-wrap justify-content-md-start justify-content-center"
            >
              {videoMediaPics?.length === 0 ? (
                <div className="d-flex justify-content-center w-100">
                  <h4>No Photos Found</h4>
                </div>
              ) : (
                <>
                  {videoMediaPics?.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                        className="d-flex align-items-end giversMediaImgMainDiv"
                      >
                        <img className="w-100" src={item} />
                        <div className="giversMediaImgCheckBoxDiv">
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="mt-5">
              <p className="m-0 mb-2 mb-3 fs-20 fw-500">Audio Recordings</p>
              <div
                style={{ gap: "50px" }}
                className="d-flex flex-wrap  justify-content-md-start justify-content-center"
              >
                {videoMediaAudio?.length === 0 ? (
                  <div className="d-flex justify-content-center w-100">
                    <h4>No Audio Found</h4>
                  </div>
                ) : (
                  <>
                    {videoMediaAudio?.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-center"
                      >
                        <div className="mediaGalleryAudioDiv">
                          <div className="px-3  d-flex align-items-center">
                            <p className="m-0 color-darkGreen font-size-14 fw-400">
                              Loremipsum
                            </p>
                          </div>
                          <img className="w-100" src={mediaGalleryAudio} />
                          <div className="audioDownloadbutton cursor-pointer">
                            <a href={`${item}`} target="_blank" download>
                              <img
                                className="w-100"
                                src={audioDownloadButton}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default GiverVideoMedia;
