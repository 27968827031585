import * as domain from "../../lib/config";
import axios from "axios";
import swal from "sweetalert";

export const GET_CATEGORIES = "[ADMIN] GET_CATEGORIES";
export const GET_CATEGORIES_ERROR = "[ADMIN] GET_CATEGORIES_ERROR";
export const GET_ALL_VIDEOS = "[ADMIN] GET_ALL_VIDEOS";
export const GET_ALL_VIDEOS_ERROR = "[ADMIN] GET_ALL_VIDEOS_ERROR";
export const VIDEOS_LOADING = "[ADMIN] VIDEOS_LOADING";
export const AD_VIDEO_LOADING = "[ADMIN] AD_VIDEO_LOADING";

export function addVideos(data, setModalCentered) {
  console.log(domain.default);
  const request = axios.post(
    `${domain.default}/learningvideos/AddlearningVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(actionLearningVideoLoading(false));
        dispatch(getAllVideos({}));
        setModalCentered(false);
      })
      .catch((error) => {
        dispatch(actionLearningVideoLoading(false));
        setModalCentered(false);
        swal("Error While Submit", "error");
        console.log(error);
      });
}

export function getVideosCategories(data) {
  console.log(domain.default);
  const request = axios.post(
    `${domain.default}/learningVideos/GetLearningCategory`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_CATEGORIES_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function getAllVideos(data) {
  console.log(domain.default);
  const request = axios.post(
    `${domain.default}/learningvideo/GetLearningVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response?.data?.LearningVideo);
        return dispatch({
          type: GET_ALL_VIDEOS,
          payload: response?.data?.LearningVideo,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_VIDEOS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function deleteVideos(data, setModalCentered) {
  console.log(domain.default);
  const request = axios.delete(
    `${domain.default}/learningvideo/DeleteLearningVideo`,
    {
      data,
    }
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        setModalCentered(false);
        dispatch(getAllVideos({}));
        dispatch(actionLearningVideoLoading(false));
      })
      .catch((error) => {
        setModalCentered(false);
        dispatch(actionLearningVideoLoading(false));
        return dispatch({
          type: GET_ALL_VIDEOS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot delete Videos",
        });
      });
}

export function LearningVideosLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: VIDEOS_LOADING,
      payload: val,
    });
  };
}

export function actionLearningVideoLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: AD_VIDEO_LOADING,
      payload: val,
    });
  };
}
