import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
import drump from "../../../images/card/drump.png";

// icons Import
import analyticsIcon from "../../../images/sideBarIcon/analytics.svg";
import giversIcon from "../../../images/sideBarIcon/givers.svg";
import managePackagesIcon from "../../../images/sideBarIcon/managePackages.svg";
import blogManagerIcon from "../../../images/sideBarIcon/blogManager.svg";
import videoIcon from "../../../images/sideBarIcon/video.svg";
import aboutIcon from "../../../images/sideBarIcon/about.svg";
//

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let deshBoard = ["analytics"],
      givers = ["givers"],
      managePackages = ["packages"],
      blogsManager = ["blogs"],
      about = ["about"],
      Videos = ["videos"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
              <Link to="/analytics" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img src={analyticsIcon} />
                  <span className="nav-text ml-3">Analytics</span>
                </div>
              </Link>
            </li>
            <li className={`${givers.includes(path) ? "mm-active" : ""}`}>
              <Link to="/givers" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img src={giversIcon} />
                  <span className="nav-text ml-3">Givers</span>
                </div>
              </Link>
            </li>
            <li
              className={`${managePackages.includes(path) ? "mm-active" : ""}`}
            >
              <Link to="/packages" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img src={managePackagesIcon} />
                  <span className="nav-text ml-3">Manage Packages</span>
                </div>
              </Link>
            </li>
            <li className={`${blogsManager.includes(path) ? "mm-active" : ""}`}>
              <Link to="/blogs" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img src={blogManagerIcon} />
                  <span className="nav-text ml-3">Blogs Manager</span>
                </div>
              </Link>
            </li>
            <li className={`${Videos.includes(path) ? "mm-active" : ""}`}>
              <Link to="/videos" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img width="30" src={videoIcon} />

                  <span className="nav-text ml-3">Videos</span>
                </div>
              </Link>
            </li>
            <li className={`${about.includes(path) ? "mm-active" : ""}`}>
              <Link to="/about" className="ai-icon">
                <div className="d-flex align-items-center">
                  <img src={aboutIcon} />
                  <span className="nav-text ml-3">About</span>
                </div>
              </Link>
            </li>
          </MM>

          {/*  */}
          {/* <div className="drum-box mt-5">
            <img src={drump} alt="" />
            <p className="fs-18 font-w500 mb-4">Start Plan Your Workout</p>
            <Link className to={"./personal-record"}>
              Check schedule
              <svg
                className="ml-3"
                width={6}
                height={12}
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 12L6 6L0 0" fill="#BCD7FF" />
              </svg>
            </Link>
          </div>

          <div className="copyright">
            <p>
              <strong>Fito Dashboard</strong> ©All Rights Reserved
            </p>
            <p>by DexignZone</p>
          </div> */}
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
