import * as domain from "./../../lib/config";
import axios from "axios";

export const STORE_NOTIFICATION = "[ADMIN] STORE_NOTIFICATION";
export const GET_ALL_NOTIFICATION = "[ADMIN] GET_ALL_NOTIFICATION";
export const GET_ALL_NOTIFICATION_ERROR = "[ADMIN] GET_ALL_NOTIFICATION_ERROR";
export const GET_NOTIFICATION_COUNT = "[ADMIN] GET_NOTIFICATION_COUNT";

export function storeNotification(data) {
  return (dispatch) => {
    return dispatch({
      type: STORE_NOTIFICATION,
      payload: data,
    });
  };
}

export function getAllNotifications(data) {
  console.log(domain.default);
  const request = axios.post(
    `${domain.default}/notification/GetAllNotification`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        dispatch(getNotificationCount({}));
        return dispatch({
          type: GET_ALL_NOTIFICATION,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_NOTIFICATION_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function getNotificationById(data) {
  console.log(domain.default);
  const request = axios.post(
    `${domain.default}/notification/GetNotificationById`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        dispatch(getAllNotifications({}));
        // return dispatch({
        //   type: GET_ALL_NOTIFICATION,
        //   payload: response.data,
        // });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_NOTIFICATION_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function getNotificationCount(data) {
  console.log(domain.default);
  const request = axios.get(
    `${domain.default}/notification/GetNotificationCount`
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_NOTIFICATION_COUNT,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_NOTIFICATION_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get counts",
        });
      });
}
