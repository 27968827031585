// local
// const Domain = "http://192.168.100.15:3001";

// live
const Domain = "https://api.fromubaby-videos.com";

// const Domain = "http://192.168.18.42:3001"
// live
// const Domain = "http://34.253.154.59:3001";

export default Domain;
