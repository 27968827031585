import * as Actions from "../actions";

const initialState = {
  loading: false,
  getAllCategories: null,
  getAllVideos: null,
  addVideoLoading: false,
};

const learningVideosReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATEGORIES: {
      return {
        ...state,
        getAllCategories: action.payload,
        loading: false,
      };
    }

    case Actions.GET_ALL_VIDEOS: {
      return {
        ...state,
        getAllVideos: action.payload,
        loading: false,
      };
    }

    case Actions.VIDEOS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case Actions.AD_VIDEO_LOADING: {
      return {
        ...state,
        addVideoLoading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default learningVideosReducer;
