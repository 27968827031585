import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import cross from "../../../images/pageIcon/cross.svg";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import { uploadFile } from "../../components/fileUpload/fileUpload";

const VideoAddModal = ({ modalCentered, setModalCentered, categories }) => {
  const dispatch = useDispatch();

  const loading = useSelector(
    ({ learningVideosReducer }) => learningVideosReducer?.addVideoLoading
  );

  const [inputData, setInputData] = useState({
    LearningCategoryId: null,
    LearningVideoName: null,
  });

  const [file, setFile] = useState({
    LinkUrlLocal: null,
    LinkUrl: null,
    LinkUrlLoading: false,
    localError: false,
  });

  console.log(inputData);

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  // file upload
  async function handleChange(event) {
    const loading = `${event.target.name}Loading`;
    setFile({ ...file, [loading]: true, localError: false });
    // setInputDataS3({ ...inputDataS3, [loading]: true, s3Error: false });

    uploadFile(event)
      .then((res) => {
        setFile({
          ...file,
          [`${event.target.name}Local`]: res[0]?.data?.FileData?.tempFileUrl,
          [event.target.name]: res[1]?.data?.result?.Location,
          [loading]: false,
        });
      })
      .catch((error) => {
        setFile({
          ...file,
          Error: true,
        });
      });
  }

  const onSubmitAddVideo = (e) => {
    e.preventDefault();
    let data = {
      LearningCategoryId: inputData?.LearningCategoryId,
      LearningVideoName: inputData?.LearningVideoName,
      LinkUrl: file?.LinkUrl,
      TempFileLink: file?.LinkUrlLocal,
    };

    console.log(data);
    if (data?.LinkUrl && data?.TempFileLink) {
      dispatch(actions.actionLearningVideoLoading(true));
      dispatch(actions.addVideos(data, setModalCentered));
      setTimeout(function () {
        setFile({
          file,
          LinkUrlLocal: null,
          LinkUrl: null,
          LinkUrlLoading: false,
          localError: false,
        });

        setInputData({
          ...setInputData,
          LearningCategoryId: null,
          LearningVideoName: null,
        });
      }, 2000);
    }
  };

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <Modal className="fade" centered show={modalCentered}>
        <div className="mb-3 py-3 px-4">
          <div
            onClick={() => setModalCentered(!modalCentered)}
            className="d-flex justify-content-end cursorPointer"
          >
            <img src={cross} />
          </div>
          <div className=" my-4">
            <Form onSubmit={onSubmitAddVideo}>
              <FormGroup>
                <Label for="exampleSelect">Select Category</Label>
                <Input
                  onChange={onChange}
                  required
                  type="select"
                  name="LearningCategoryId"
                  id="exampleSelect"
                >
                  <option value="">Select Categories</option>
                  {categories?.map((item) => (
                    <option value={item.id} key={item?.id}>
                      {item?.LearningVideoCategoryName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Video Name</Label>
                <Input
                  type="text"
                  required
                  onChange={onChange}
                  name="LearningVideoName"
                  id="exampleEmail"
                  placeholder="with a placeholder"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleFile">File</Label>
                <Input
                  onChange={handleChange}
                  type="file"
                  name="LinkUrl"
                  id="exampleFile"
                />
                {file?.LinkUrlLoading && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                )}
                <FormText color="muted">
                  This is some placeholder block-level help text for the above
                  input. It's a bit lighter and easily wraps to a new line.
                </FormText>
              </FormGroup>
              <div className="d-flex justify-content-end">
                {file?.localError ? (
                  <div>
                    <p style={{ color: "red", margin: "0" }}>
                      Error While Uploading
                    </p>
                  </div>
                ) : null}
                <div className="">
                  <Button type="submit" className={`bgDGreen py-2 ml-2`}>
                    {loading ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoAddModal;
