import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";

const Chat = ({ PerfectScrollbar, toggleChatBox, toggleTab }) => {
  const dispatch = useDispatch();

  const notificationData = useSelector(
    ({ notificationReducer }) => notificationReducer
  );
  const allNotification = notificationData?.getAllNotification;

  const openNotification = (item) => {
    let data = {
      id: item?.id,
    };
    dispatch(actions.getNotificationById(data));
  };

  console.log(allNotification);

  return (
    <div
      className={`tab-pane fade  ${toggleTab === "chat" ? "active show" : ""}`}
      id="chat"
      role="tabpanel"
    >
      <div className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box`}>
        <div className="py-3 px-4">
          <div>
            <input
              style={{ border: "none", backgroundColor: "#F0F0F0" }}
              className="w-100 br-8 p-2"
              placeholder="Search by Giver Name"
            />
          </div>
        </div>
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? "ps ps--active-y" : ""
          }`}
          id="DZ_W_Contacts_Body"
        >
          <ul className="contacts">
            <li className="name-first-letter">{"notify"}</li>
            {allNotification?.map((item) => (
              <li
                onClick={() => openNotification(item)}
                key={item.id}
                className={`active ${
                  item.IsOpen === true ? "" : "openNotification"
                } dz-chat-user`}
              >
                <div className="d-flex ">
                  <div className="my-2">
                    <span className="fw-600 m-0">{item.Title}</span>
                    <p className="fs-16 m-0 fw-400">{item.Description}</p>
                    <p className="m-0 fs-12">
                      {" "}
                      {new Date(item.createdAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default Chat;
