import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Button, Modal } from "react-bootstrap";
import cross from "../../../images/pageIcon/cross.svg";
import { useSelector } from "react-redux";

const ActionsModal = ({
  modalCentered,
  setModalCentered,
  action,
  confirmCall,
}) => {
  const confirm = () => {
    confirmCall();
    // setModalCentered(!modalCentered);
  };

  const loading = useSelector(
    ({ learningVideosReducer }) => learningVideosReducer?.addVideoLoading
  );

  console.log("action:", action)
  console.log(loading);
  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <div className="px-3">
        <Modal className="fade" centered show={modalCentered}>
          <div className="mb-3 py-3 px-4">
            <div
              onClick={() => setModalCentered(!modalCentered)}
              className="d-flex justify-content-end cursorPointer"
            >
              <img src={cross} />
            </div>
            <div className="text-center my-4">
              <p className="fs-20 fw-500 greyColor m-0">
                Are you sure you want to&nbsp;
                {`${
                  action === "deactivate"
                    ? "deactivate"
                    : action === "activate"
                    ? "activate"
                    : action === "delete"
                    ? "delete"
                    : action === "priceChange"
                    ? "change price"
                    : ""
                }?`}
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="">
                <Button
                  onClick={() => setModalCentered(!modalCentered)}
                  className="py-2 mr-2 color-white"
                  variant="light"
                >
                  Close
                </Button>
              </div>
              <div className="">
                <Button
                  onClick={confirm}
                  className={`${
                    action === "deactivate" || action === "delete"
                      ? "bgRed"
                      : "bgDGreen"
                  }   py-2 ml-2`}
                >
                  {loading ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
              {/* <div className="">
                <Button
                  onClick={confirm}
                  className={`${
                    action === "deactivate" || action === "delete"
                      ? "bgRed"
                      : "bgDGreen"
                  }   py-2 ml-2`}
                >
                  Confirm
                </Button>
              </div> */}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ActionsModal;
