import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import blogImg from "../../../images/pageIcon/blogImg.png";
import SelectAddBlogModal from "../../components/modals/selectAddbogModal";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import history from "../../../@history";
import TaskList from "../../pages/Loader/preloaderBlog";
import ActionsModal from "../../components/modals/actionsModal";

const BlogsManager = () => {
  const isImage = (url) => {
    return /^\[webp|jpeg|jpg|png|avif|gif|svg]\s?I{3}$/.test(url);
  };

  const dispatch = useDispatch();

  const [modalCentered, setModalCentered] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [blogId, setBlogId] = useState(null);
  const data = useSelector(({ blogReducer }) => blogReducer);

  console.log(data?.blogs?.Blogs);
  const blogData = data?.blogs?.Blogs;
  const loading = data?.loading;

  const toggleModal = () => {
    setModalCentered(true);
  };

  useEffect(() => {
    let data = {};
    dispatch(actions.blogLoading(data));
    dispatch(actions.getBlogs(data));
  }, []);

  const deleteBlog = () => {
    let data = {
      id: blogId,
    };
    console.log(data);
    dispatch(actions.deleteBlog(data));
  };

  const openModal = (id) => {
    setBlogId(id);
    setDeleteModal(true);
  };

  console.log("blogData:", blogData)

  return (
    <>
      <Col lg={12}>
        <Card>
          <div className="px-4 pt-4 d-flex justify-content-between">
            <div className="input-group search-area d-lg-inline-flex d-none mr-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Giver Name, Email"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                      fill="#A4A4A4"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <Button onClick={toggleModal} className="bgDGreen">
              Add Blog
            </Button>
          </div>
          <Card.Body>
            {!loading && !loading ? (
              <div
                style={{ border: "1px solid #E8E8E8", borderRadius: "12px" }}
              >
                {blogData?.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      border: "1px solid #E8E8E8",
                      borderRadius: "12px",
                    }}
                    className="m-3 p-3 d-flex flex-wrap flex-md-nowrap  justify-content-center justify-content-md-start cursorPointer"
                  >
                    <div className="mr-md-5 mb-md-0 mb-4">
                      {isImage(item.BlogImage) ? (
                        <img
                          alt=""
                          src={item.BlogImage}
                          width="170"
                          height="170"
                        />
                      ) : (
                        <video autoPlay muted width="170"
                        height="170">
                          <source
                            src={item.BlogImage}
                          ></source>
                        </video>
                      )}
                      {/* <img src={item.BlogImage} width="170" height="170" /> */}
                    </div>
                    <div className=" w-100">
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <p className="dGreenColor fw-500  fs-18 m-0">
                            {item.BlogTitle}
                          </p>
                          <p className="fs-13 greyColor fw-500">
                            {new Date(item.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </p>
                        </div>
                        <div style={{ gap: "10px" }} className="d-flex">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                `/editblog/${
                                  item?.BlogNumber === 1
                                    ? "form1"
                                    : item?.BlogNumber === 2
                                    ? "form2"
                                    : item?.BlogNumber === 3
                                    ? "form3"
                                    : null
                                }/${item?.id}`
                              )
                            }
                          >
                            Edit
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => openModal(item?.id)}
                          >
                            delete
                          </div>
                        </div>
                      </div>
                      <div style={{ height: "100px", overflow: "hidden" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.BlogDescription,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <TaskList />
            )}
          </Card.Body>
        </Card>
        <SelectAddBlogModal
          modalCentered={modalCentered}
          setModalCentered={setModalCentered}
        />
        <ActionsModal
          modalCentered={deleteModal}
          setModalCentered={setDeleteModal}
          action="delete"
          confirmCall={deleteBlog}
        />
      </Col>
    </>
  );
};

export default BlogsManager;
