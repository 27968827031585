import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Button, Modal } from "react-bootstrap";
import cross from "../../../images/pageIcon/cross.svg";
import blogFormat from "../../../images/pageIcon/blogformat.png";
import blogFormat2 from "../../../images/pageIcon/blogformat2.png";
import blogFormat3 from "../../../images/pageIcon/blogformat3.png";

const SelectAddBlogModal = ({ modalCentered, setModalCentered }) => {
  const [selected, setSelected] = useState();

  const blogData = [
    {
      id: 1,
      img: blogFormat3,
      name: "form1",
    },
    {
      id: 2,
      img: blogFormat,
      name: "form2",
    },
    {
      id: 3,
      img: blogFormat2,
      name: "form3",
    },
  ];

  const onselect = (id) => {
    setSelected(id);
  };

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <Modal className="fade" centered show={modalCentered}>
        <div className="mb-3 py-3 px-4">
          <div
            onClick={() => setModalCentered(!modalCentered)}
            className="d-flex justify-content-end cursorPointer"
          >
            <img src={cross} />
          </div>
          <p className="m-0 fs-20 dBlackColor fw-500">
            Choose the format for blog
          </p>
          <div
            style={{ gap: "30px" }}
            className={`d-flex justify-content-around my-5`}
          >
            {blogData?.map((item) => (
              <div
                className={`${
                  selected === item.name ? "blogSelectedModal" : "blogModal"
                } cursor-pointer d-flex align-items-center`}
                onClick={() => onselect(item?.name)}
                key={item.id}
              >
                <img className="w-100" src={item?.img} />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end">
            <div className="">
              {selected ? (
                <Link to={`/addblog/${selected}`}>
                  <Button className={`bgDGreen py-2 ml-2`}>Add</Button>
                </Link>
              ) : (
                <Button className={`bgDGreen py-2 ml-2`}>Add</Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectAddBlogModal;
