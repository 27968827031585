import React from "react";
import { Link } from "react-router-dom";
import giverImg from "../../../images/pageIcon/giverImg.png";
import mediaGalleryAudio from "../../../images/pageIcon/mediaGalleryAudio.svg";
import audioDownloadButton from "../../../images/pageIcon/audioDownloadButton.svg";

const GiverMedia = ({ activeTab, setActiveTab, giverAudios, giverGallery }) => {
  return (
    <>
      <div>
        <p className="m-0 fs-20 fw-500 mb-3">Images</p>
        <div
          style={{ gap: "50px" }}
          className="d-flex flex-wrap justify-content-md-start justify-content-center"
        >
          {giverGallery?.length === 0 ? (
            <div className="d-flex justify-content-center w-100">
              <h4>No Photos Found</h4>
            </div>
          ) : (
            <>
              {giverGallery?.map((item) => (
                <div>
                  <div
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                    className="d-flex align-items-end giversMediaImgMainDiv"
                  >
                    <img className="w-100" src={item} />
                    <div className="giversMediaImgCheckBoxDiv">
                      <input type="checkbox" />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        {/*  */}

        <div className="mt-5">
          <p className="m-0 mb-2 mb-3 fs-20 fw-500">Audio Recordings</p>
          <div
            style={{ gap: "50px" }}
            className="d-flex flex-wrap  justify-content-md-start justify-content-center"
          >
            {giverAudios?.length === 0 ? (
              <div className="d-flex justify-content-center w-100">
                <h4>No Audio Found</h4>
              </div>
            ) : (
              <>
                {giverAudios?.map((item) => (
                  <div key={item?.id} className="d-flex justify-content-center">
                    <div className="mediaGalleryAudioDiv">
                      <div className="px-3  d-flex align-items-center">
                        <p className="m-0 color-darkGreen font-size-14 fw-400">
                          Loremipsum
                        </p>
                      </div>
                      <img className="w-100" src={mediaGalleryAudio} />
                      <div className="audioDownloadbutton cursor-pointer">
                        <a href={`${item}`} target="_blank" download>
                          <img className="w-100" src={audioDownloadButton} />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GiverMedia;
