import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button, FormGroup } from "react-bootstrap";
import blogImg from "../../../../images/pageIcon/blogImg.png";
import { Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index";
import axios from "axios";
import domain from "../../../../lib/config";
import { useParams } from "react-router";
import fileUpload from "../../../../images/fileUpload.svg";
import videoThumbnail from "../../../../images/videothumbnail.jpg";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const instance = new axios.create({});
delete instance.defaults.headers.common[`Authorization`];

const isImage = (url) => {
  console.log("url", url);
  return /^\[webp|jpeg|jpg|png|avif|gif|svg]\s?I{3}$/.test(url);
};

const AddBlog = () => {
  const dispatch = useDispatch();
  const { id, name } = useParams();
  const [file, setFile] = useState({
    blogImgLoading: false,
    blogVideo1Loading: false,
    blogVideo2Loading: false,
    blogVideo3Loading: false,
  });
  const [descEditorState, setDescEditorState] = useState();
  const [videoDesc1EditorState, setVideoDesc1EditorState] = useState();

  const [videoDesc2EditorState, setVideoDesc2EditorState] = useState();

  const [videoDesc3EditorState, setVideoDesc3EditorState] = useState();

  const blogById = useSelector(
    ({ blogReducer }) => blogReducer?.blogById?.Blog
  );

  useEffect(() => {
    if (blogById) {
      setFile({
        ...file,
        blogVideo1: blogById?.BlogVideo1,
        blogImg: blogById?.BlogImage,
        blogVideo2: blogById?.BlogVideo2,
        blogVideo3: blogById?.BlogVideo3,
      });
    }

    if (blogById?.BlogDescription) {
      let contentBlock = htmlToDraft(blogById && blogById?.BlogDescription);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );

        const outputEditorState = EditorState.createWithContent(contentState);
        setDescEditorState(outputEditorState);
        console.log(outputEditorState, "decxxxxxxx");
      }
    }

    if (blogById?.BlogBody) {
      let contentBlock = htmlToDraft(blogById && blogById?.BlogBody);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const outputEditorState = EditorState.createWithContent(contentState);
        setVideoDesc1EditorState(outputEditorState);
        console.log(outputEditorState, "decxxxxxxx");
      }
    }
    if (blogById?.BlogBody2) {
      let contentBlock = htmlToDraft(blogById && blogById?.BlogBody2);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const outputEditorState = EditorState.createWithContent(contentState);
        setVideoDesc2EditorState(outputEditorState);
        console.log(outputEditorState, "decxxxxxxx");
      }
    }
    if (blogById?.BlogBody3) {
      let contentBlock = htmlToDraft(blogById && blogById?.BlogBody3);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const outputEditorState = EditorState.createWithContent(contentState);
        setVideoDesc3EditorState(outputEditorState);
        console.log(outputEditorState, "decxxxxxxx");
      }
    }
  }, [blogById]);

  useEffect(() => {
    let data = {
      id: parseInt(id),
    };
    dispatch(actions.blogLoading(data));
    dispatch(actions.getBlogById(data));
  }, []);

  // file upload s3
  async function handleChange(event) {
    console.log(event);
    const loading = `${event.target.name}Loading`;
    setFile({ ...file, [loading]: true });
    const data = new FormData();
    data.append("UserFile", event.target.files[0]);
    axios
      .post(`${domain}/uservideos/uploadfiletosebucket`, data)
      .then((response) => {
        setFile({
          ...file,
          [event.target.name]: response?.data?.result?.Location,
          [loading]: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    let data = {
      BlogTitle: e.target.title.value,
      BlogDescription: draftToHtml(
        convertToRaw(descEditorState?.getCurrentContent())
      ),

      BlogImage: file?.blogImg,

      BlogVideo1: file?.blogVideo1,
      BlogNumber:
        name === "form1"
          ? 1
          : name === "form2"
          ? 2
          : name === "form3"
          ? 3
          : null,
    };
    console.log(data, "contentBlock");

    // videoDesc1EditorState

    let data2 = {
      BlogVideo2: file?.blogVideo2,
      BlogVideo3: file?.blogVideo3,
    };

    let data3 = {
      // BlogBody: draftToHtml(
      //   convertToRaw(videoDesc1EditorState?.getCurrentContent())
      // ),
      BlogBody:
        name === "form3"
          ? draftToHtml(
              convertToRaw(videoDesc1EditorState?.getCurrentContent())
            )
          : null,
      BlogBody2:
        name === "form3"
          ? draftToHtml(
              convertToRaw(videoDesc2EditorState?.getCurrentContent())
            )
          : null,
      BlogBody3:
        name === "form3"
          ? draftToHtml(
              convertToRaw(videoDesc3EditorState?.getCurrentContent())
            )
          : null,
    };

    // this is for form 1 dispatch

    if (name === "form1") {
      if (id) {
        data.id = parseInt(id);
        dispatch(actions.updateBlog(data));
      } else {
        console.log(data);
        dispatch(actions.addBlog(data));
      }
    }

    // this is for form 2 dispatch
    if (name === "form2") {
      let merged = { ...data, ...data2 };
      console.log(merged);
      if (id) {
        merged.id = parseInt(id);
        dispatch(actions.updateBlog(merged));
      } else {
        dispatch(actions.addBlog(merged));
      }
    }

    if (name === "form3") {
      let merged = { ...data, ...data2, ...data3 };
      console.log(merged);
      if (id) {
        merged.id = parseInt(id);
        dispatch(actions.updateBlog(merged));
      } else {
        dispatch(actions.addBlog(merged));
      }
    }
  };

  const onDescEditorStateChange = (editorState) => {
    setDescEditorState(editorState);
  };

  const onVideoDesc1EditorStateChange = (editorState) => {
    setVideoDesc1EditorState(editorState);
  };
  const onVideoDesc2EditorStateChange = (editorState) => {
    setVideoDesc2EditorState(editorState);
  };
  const onVideoDesc3EditorStateChange = (editorState) => {
    setVideoDesc3EditorState(editorState);
  };

  console.log("file:", file);
  useEffect(() => {
    console.log("i ran");
  }, [file]);

  return (
    <>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <div>
              <form onSubmit={onSubmit}>
                <div className="d-flex w-100">
                  <div className="w-100 m-2">
                    <FormGroup>
                      <Label for="exampleEmail">Blog title</Label>
                      <Input
                        defaultValue={blogById?.BlogTitle}
                        type="text"
                        name="title"
                        id="exampleEmail"
                        placeholder="Blog title"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="w-100 m-2">
                  <label>Blog Image</label>
                  <div
                    style={{ width: "200px" }}
                    className="imageUpload d-flex justify-content-center align-items-center"
                  >
                    <label for="blogImg">
                      {file?.blogImgLoading ? (
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : isImage(file?.blogImg) ? (
                        <img
                          alt=""
                          src={file?.blogImg ? file?.blogImg : fileUpload}
                          width="170"
                          height="170"
                        />
                      ) : (
                        <video
                          width="170"
                          src={file.blogImg}
                          height="170"
                          autoPlay="true"
                          muted
                        />
                      )}
                    </label>
                    <Input
                      id="blogImg"
                      name="blogImg"
                      onChange={handleChange}
                      type="file"
                    />
                  </div>
                </div>

                <div className="d-flex w-100">
                  <div className="w-100 m-2">
                    <FormGroup>
                      <Label for="exampleEmail">Blog description</Label>
                      <Editor
                        editorStyle={{ height: "400px", background: "white" }}
                        editorState={descEditorState}
                        onEditorStateChange={onDescEditorStateChange}
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                          ],
                        }}
                      />
                      {/* <Input
                        defaultValue={blogById?.BlogDescription}
                        type="textarea"
                        name="blogdescription"
                        id="exampleEmail"
                        placeholder="description"
                      /> */}
                    </FormGroup>
                  </div>
                </div>
                {/*  */}

                {name === "form1" || name === "form2" ? (
                  <>
                    {/* <div className="d-flex w-100">
                      <div className="w-100 m-2">
                        <FormGroup>
                          <Label for="exampleEmail">Blog Body</Label>
                          <Input
                            defaultValue={blogById?.BlogBody}
                            type="textarea"
                            name="blogbody"
                            id="exampleEmail"
                            placeholder="body"
                          />
                        </FormGroup>
                      </div>
                    </div> */}
                    <div className="d-flex align-items-center w-100">
                      {/* blog video 1..........
                  ...............................
                  ............................. */}
                      <div className="w-100 m-2">
                        <label>Blog Image</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo1">
                            {
                              file?.blogVideo1Loading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              ) : isImage(file?.blogVideo1) ? (
                                <img
                                  alt=""
                                  src={
                                    file?.blogVideo1
                                      ? file?.blogVideo1
                                      : fileUpload
                                  }
                                  width="170"
                                  height="170"
                                />
                              ) : (
                                <video
                                  width="170"
                                  src={file.blogVideo1}
                                  height="170"
                                  autoPlay="true"
                                  muted
                                />
                                // <video autoPlay width="170" height="170">
                                //   <source
                                //     src={
                                //       file?.blogVideo1
                                //         ? file?.blogVideo1
                                //         : fileUpload
                                //     }
                                //   ></source>
                                // </video>
                              )
                              //  (
                              //   <img
                              //     className="w-100 object-fit-cover cursor-pointer"
                              //     src={
                              //       file?.blogVideo1
                              //         ? file?.blogVideo1
                              //         : fileUpload
                              //     }
                              //   />
                              // )
                            }
                          </label>
                          <Input
                            id="blogVideo1"
                            name="blogVideo1"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      {/* blog video 2..........
                  ...............................
                  ............................. */}
                      {name === "form2" ? (
                        <>
                          <div className="w-100 m-2">
                            <label>Blog Image</label>
                            <div
                              style={{ width: "200px" }}
                              className="imageUpload d-flex justify-content-center align-items-center"
                            >
                              <label for="blogVideo2">
                                {
                                  file?.blogVideo2Loading ? (
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only"></span>
                                    </div>
                                  ) : isImage(file?.blogVideo2) ? (
                                    <img
                                      alt=""
                                      src={
                                        file?.blogVideo2
                                          ? file?.blogVideo2
                                          : fileUpload
                                      }
                                      width="170"
                                      height="170"
                                    />
                                  ) : (
                                    <video
                                      width="170"
                                      src={file.blogVideo2}
                                      height="170"
                                      autoPlay="true"
                                      muted
                                    />
                                  )

                                  // (
                                  //   <img
                                  //     className="w-100 object-fit-cover cursor-pointer"
                                  //     src={
                                  //       file?.blogVideo2
                                  //         ? file?.blogVideo2
                                  //         : fileUpload
                                  //     }
                                  //   />
                                  // )
                                }
                              </label>
                              <Input
                                id="blogVideo2"
                                name="blogVideo2"
                                onChange={handleChange}
                                type="file"
                              />
                            </div>
                          </div>

                          {/* blog video 3..........
                  ...............................
                  ............................. */}

                          <div className="w-100 m-2">
                            <label>Blog Image 5</label>
                            <div
                              style={{ width: "200px" }}
                              className="imageUpload d-flex justify-content-center align-items-center"
                            >
                              <label for="blogVideo3">
                                {
                                  file?.blogVideo3Loading ? (
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only"></span>
                                    </div>
                                  ) : isImage(file?.blogVideo3) ? (
                                    <img
                                      alt=""
                                      src={
                                        file?.blogVideo3
                                          ? file?.blogVideo3
                                          : fileUpload
                                      }
                                      width="170"
                                      height="170"
                                    />
                                  ) : (
                                    <video
                                      width="170"
                                      src={file.blogVideo3}
                                      height="170"
                                      autoPlay="true"
                                      muted
                                    />
                                  )
                                  // (
                                  //   <img
                                  //     className="w-100 object-fit-cover cursor-pointer"
                                  //     src={
                                  //       file?.blogVideo3
                                  //         ? file?.blogVideo3
                                  //         : fileUpload
                                  //     }
                                  //   />
                                  // )
                                }
                              </label>
                              <Input
                                id="blogVideo3"
                                name="blogVideo3"
                                onChange={handleChange}
                                type="file"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="d-flex justify-content-end w-100">
                        <div>
                          <Button type="submit" className="">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between">
                    {/* blog video 1 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Image</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo1">
                            {
                              file?.blogVideo1Loading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              ) : isImage(file?.blogVideo1) ? (
                                <img
                                  alt=""
                                  src={
                                    file?.blogVideo1
                                      ? file?.blogVideo1
                                      : fileUpload
                                  }
                                  width="170"
                                  height="170"
                                />
                              ) : (
                                <video
                                  width="170"
                                  src={file.blogVideo1}
                                  height="170"
                                  autoPlay="true"
                                  muted
                                />
                              )
                              // (
                              //   <img
                              //     className="w-100 object-fit-cover cursor-pointer"
                              //     src={
                              //       file?.blogVideo1
                              //         ? file?.blogVideo1
                              //         : fileUpload
                              //     }
                              //   />
                              // )
                            }
                          </label>
                          <Input
                            id="blogVideo1"
                            name="blogVideo1"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog Image description
                            </Label>

                            <Editor
                              editorStyle={{
                                height: "400px",
                                background: "white",
                              }}
                              editorState={videoDesc1EditorState}
                              onEditorStateChange={
                                onVideoDesc1EditorStateChange
                              }
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  "fontFamily",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                ],
                              }}
                            />
                            {/* <Input
                              defaultValue={blogById?.BlogBody}
                              type="textarea"
                              name="blogbody"
                              id="exampleEmail"
                              placeholder="description"
                            /> */}
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    {/* blog video 2 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Image 2</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo2">
                            {
                              file?.blogVideo2Loading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              ) : isImage(file?.blogVideo2) ? (
                                <img
                                  alt=""
                                  src={
                                    file?.blogVideo2
                                      ? file?.blogVideo2
                                      : fileUpload
                                  }
                                  width="170"
                                  height="170"
                                />
                              ) : (
                                <video
                                  width="170"
                                  src={file.blogVideo2}
                                  height="170"
                                  autoPlay="true"
                                  muted
                                />
                              )
                              // (
                              //   <img
                              //     className="w-100 object-fit-cover cursor-pointer"
                              //     src={
                              //       file?.blogVideo2
                              //         ? file?.blogVideo2
                              //         : fileUpload
                              //     }
                              //   />
                              // )
                            }
                          </label>
                          <Input
                            id="blogVideo2"
                            name="blogVideo2"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog Image description
                            </Label>

                            <Editor
                              editorStyle={{
                                height: "400px",
                                background: "white",
                              }}
                              editorState={videoDesc2EditorState}
                              onEditorStateChange={
                                onVideoDesc2EditorStateChange
                              }
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  "fontFamily",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                ],
                              }}
                            />
                            {/* <Input
                              defaultValue={blogById?.BlogBody2}
                              type="textarea"
                              name="blogbody2"
                              id="exampleEmail"
                              placeholder="description"
                            /> */}
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    {/* blog video 3 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Image</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo3">
                            {
                              file?.blogVideo3Loading ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              ) : isImage(file?.blogVideo3) ? (
                                <img
                                  alt=""
                                  src={
                                    file?.blogVideo3
                                      ? file?.blogVideo3
                                      : fileUpload
                                  }
                                  width="170"
                                  height="170"
                                />
                              ) : (
                                <video
                                  width="170"
                                  src={file.blogVideo3}
                                  height="170"
                                  autoPlay="true"
                                  muted
                                />
                              )
                              // (
                              //   <img
                              //     className="w-100 object-fit-cover cursor-pointer"
                              //     src={
                              //       file?.blogVideo3
                              //         ? file?.blogVideo3
                              //         : fileUpload
                              //     }
                              //   />
                              // )
                            }
                          </label>
                          <Input
                            id="blogVideo3"
                            name="blogVideo3"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog image description
                            </Label>

                            <Editor
                              editorStyle={{
                                height: "400px",
                                background: "white",
                              }}
                              editorState={videoDesc3EditorState}
                              onEditorStateChange={
                                onVideoDesc3EditorStateChange
                              }
                              toolbar={{
                                options: [
                                  "inline",
                                  "fontSize",
                                  "fontFamily",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                ],
                              }}
                            />
                            {/* <Input
                              defaultValue={blogById?.BlogBody3}
                              type="textarea"
                              name="blogbody3"
                              id="exampleEmail"
                              placeholder="description"
                            /> */}
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-end">
                      <div>
                        <Button type="submit" className="">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddBlog;
